import systemVerilogForAsicFpgaDesignAndSimulationImage from "../../components/ProgramCatalog/assets/system-verilog-for-asic-fpga-design-and-simulation.webp";
import entcImage from "../../components/CoursePage/assets/entc-logo.webp";
import abarajithanGnaneswaran from "../../components/CoursePage/assets/instructor-img/Abarajithan Gnaneswaran.webp";
import kaveeshaYalegama from "../../components/CoursePage/assets/instructor-img/kaveesha-yalegama.webp";
import kithminWickremasinghe from "../../components/CoursePage/assets/instructor-img/Kithmin.webp";
import hasiniAthukorale from "../../components/CoursePage/assets/instructor-img/Hasini_Athukorale.webp";
import supunk from "../../components/CoursePage/assets/instructor-img/supun.jpg";
import pasinduSandima from "../../components/CoursePage/assets/instructor-img/Pasindu-Sandima.webp";
import subodhaCharles from "../../components/CoursePage/assets/instructor-img/Subodha Charles.webp";
import rangaRodrigo from "../../components/CoursePage/assets/instructor-img/Ranga Rodrigo.webp";
import svdsFlyer from "../../components/CoursePage/assets/SVDS-main-flyer-min.webp";
import jazoolee from "../../components/CoursePage/assets/instructor-img/Jazoolee.jpg";
import achintha from "../../components/CoursePage/assets/instructor-img/achintha.png";
import { ECourseStatus, ICourse } from "../utils/utils";

export const systemVerilogForAsicFpgaDesignAndSimulation: ICourse = {
  header: {
    courseImage: systemVerilogForAsicFpgaDesignAndSimulationImage,
    programCategory: "Short Course",
    difficultyLevel: "Beginner",
    courseTitle: "{System}Verilog for ASIC/FPGA Design & Simulation (Batch 02)",
    specialization: "Build Your Own CPU",
    courseDescription:
      "The importance of processor design becomes evident when we consider the rapid pace of technological advancement in the field of computing. Get comprehensive training on {System}Verilog for ASIC/FPGA Design & Simulation throughout 8 weeks.",
    registerButton: "Registration Open",
    registerLink: "https://docs.google.com/forms/d/e/1FAIpQLSd2cjIGmpoDVHi2J31nP8ncAC3YFQACkrEaQ5b292A-o8x6kg/viewform?usp=pp_url",
    secondaryName: "",
  },
  headerSecondSection: {
    starCount: 5,
    reviewCount: 30,
    registrationDeadline: "05 February 2023",
    parameterOne: "Estimated Time",
    parameterTwo: "8 Weeks",
    parameterThree: "New Course",
    collaborationLogos: [
      {
        collaborationLogo: entcImage,
        collaborationLogosName: "ENTC Logo",
      },
     
    ],
  },
  courseOutline: [
    {
      session: "",
      sessionContent: [
        {
          heading: "Introduction  to HDLs, FPGAs, Synthesis, Verification",
          description: "",
          subContent: [],
        },
        {
          heading:
            "Learn SystemVerilog design and simulation through seven digital circuits:",
          description: "",
          subContent: [
            {
              heading: "",
              description: "1-bit full adder",
            },
            {
              heading: "",
              description: "N-bit full adder",
            },
            {
              heading: "",
              description: "Combinational ALU",
            },
            {
              heading: "",
              description: "Counter",
            },
            {
              heading: "",
              description: "Parallel to Serial Converter (State machine)",
            },
            {
              heading: "",
              description: "FIR Filter",
            },
            {
              heading: "",
              description: "Matrix-Vector Multiplier",
            },
          ],
        },
        {
          heading: "Best practices, gotchas & more",
          description: "",
          subContent: [],
        },
        {
          heading: "OSIC Tools, OpenLane, and TinyTapeout",
          description: "",
          subContent: [],
        },
      ],
    },
  ],
  faqs: [
    {
      question:
        "I have no prior knowledge of SystemVerilog or processor design. Will I be able to do this course?",
      answer:
        "The course does not require any prior experience in processor design. As long as you have a background in basic computer programming, we will provide you with the necessary ingredients to get started in this domain and become an expert.",
    },
    {
      question:
        "Do I need electronic equipment/software licenses to complete this course?",
      answer:
        "No hardware is required for the course. The required software licenses will be provided to you since we have partnered with Synopsys for the course. You will get access to Synopsys tools.",
    },
    {
      question:
        "Will I be able to build things at the end of the course, or is it only theory?",
      answer:
        "Of course, you will be able to build things. The course is outcome-oriented, so at the end of the course, you will know how to develop cool processor components from scratch.",
    },
    {
      question: "Is the course taught online?",
      answer:
        "Yes. The course lectures are all conducted online. We will arrange hands-on sessions at ENTC laboratories as required.",
    },
    {
      question: "Will lecture recordings be made available?",
      answer:
        "Yes. All course materials will be made available through an online learning management system called Moodle. All paid participants will be granted access to the course Moodle page.",
    },
  ],
  experts: [
    {
      image: abarajithanGnaneswaran,
      name: "Mr. Abarajithan Gnaneswaran",
      designation: "PhD Candidate, University of California San Diego, USA",
    },
    {
      image: kithminWickremasinghe,
      name: "Mr. Kithmin Wickremasinghe",
      designation: "MASc, Electrical and Computer Eng., UBC, Canada",
    },
    {
      image: supunk,
      name: "Mr. Supun Kuruppu",
      designation: "Associate Electronics Engineer, Paraqum Technologies, Sri Lanka",
    },
    {
      image: jazoolee,
      name: "Mr. Jazoolee Ahamed",
      designation: "3rd Year Undergraduate, ENTC, UoM",
    },
    {
      image: achintha,
      name: "Dr. Achintha Iroshan",
      designation: "Staff Program Manager, Synopsys",
    },
    /* {
      image: kaveeshaYalegama,
      name: "Mr. Kaveesha Yalegama",
      designation: "Final year undergraduate, ENTC, UoM",
    }, */

    {
      image: subodhaCharles,
      name: "Dr. Subodha Charles",
      designation: "Senior Lecturer, ENTC, UoM",
    },
   
  ],
  studentReviews: [
    "On the 1st day, when I saw the registration details, I went through the course curriculum and I joined the course with an expectation. The team fulfilled my expectations 100%.",
    "The course is very interesting and provides a good knowledge in the intended learning area. I would like to see more courses like this in future.",
    "This was a great course. Although the instructors couldn't touch all the aspects, guiding the students on what to follow and refer to is a great thing. Grateful for the organizers and instructors sharing their knowledge. Hope to join the next courses as well.",
    "The personal ML/embedded projects that the instructors shared as case studies were really inspiring. Sharing the ups and downs and what to expect along this path is a real motivator!",
    "Flow of the content was really great. It will really help to beginners who are entering to ML & Edge computing.",
  ],
  facts: [
    {
      fact: "SystemVerilog is not an entirely new RTL language. With its rich set of extensions to the existing Verilog HDL, SystemVerilog is backward compatible with both Verilog 95 and Verilog 2001. Many of these extensions to Verilog make it easier to create accurate, synthesizable models of designs of any size",
      factBold: "",
      author: "-EETimes-",
    },
    {
      fact: "The global Application Processor Market is expected to reach USD 38 billion by 2024, at a CAGR of 5.5% from 2018 to 2024.",
      factBold: "",
      author: "-MarketWatch-",
    },
  ],
  classSchedule: [
    "12 February 2023 (Sunday): 6.30 PM - 9 PM (Anyone can join the first session for free)",
    "18 February 2023 (Saturday): 6 PM - 10.30 PM",
    "19 February 2023 (Sunday): 9 AM - 1 PM",
    "25 February 2023 (Saturday): 6 PM - 10.30 PM",
    "26 February 2023 (Sunday): 9 AM - 1 PM",
    "4 March 2023 (Saturday): 6 PM - 10.30 PM",
    "5 March 2023 (Sunday): 9 AM - 1 PM",
    "11 March 2023 (Saturday): 6 PM - 10.30 PM",
    "12 March 2023 (Sunday): 9 AM - 1 PM",
    "18 March 2023 (Saturday): 6 PM - 10.30 PM",
    "19 March 2023 (Sunday): 9 AM - 1 PM",
    "25 March 2023 (Saturday): 6 PM - 10.30 PM",
    "26 March 2023 (Sunday): 9 AM - 1 PM",
    "1 April 2023 (Saturday): 6 PM - 10.30 PM",
    "2 April 2023 (Sunday): 10 AM - 2 PM",
    "8 April 2023 (Saturday): 6 PM - 10.30 PM",
    "9 April 2023 (Sunday): 9 AM - 1 PM",
  ],
  prerequisite: "Basic knowledge of programming in any language.",
  isCourseFlyer: true,
  flyer: {
    description:
      'Learn more about "{System}Verilog for ASIC/FPGA Design & Simulation" short course from the course announcement flyer',
    button: "View Course Flyer",
    image: svdsFlyer,
  },
  courseStatus: ECourseStatus.NEW,
  youtubeVideoUrl: "https://www.youtube-nocookie.com/embed/ClScIlHIBbk",
  showTimer: false,
  learnMore: {
    description:
      "Learn more about SystemVerilog design & simulation and the custom processor design market in general",
    button: "Read More",
    articleLink:
      "https://medium.com/@SkillSurfLK/embedded-machine-learning-for-edge-computing-4598eb8df1b1",
  },
};
