import { Button } from "react-bootstrap";
import NavBar from "../../shared/components/NavBar/NavBar";
import Footer from "../../shared/components/Footer/Footer";
import "./PaymentDetails.scss";
import profile_icon from "./assets/profile.svg";
import { Link, useLocation } from "react-router-dom";
import skill_surf_logo from "../../components/Home/assets/footer_logo.png";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";

pdfMake.vfs = pdfFonts.pdfMake?.vfs;

const PaymentDetails = () => {
  const { state } = useLocation();

  const generatePaymentReceiptPDF = (data: any) => {
    const documentDefinition = {
      content: [
        {
          image: skill_surf_logo,
          width: 150,
          alignment: "left",
          margin: [0, 0, 0, 10],
        },
        {
          canvas: [
            {
              type: "rect",
              x: 0,
              y: 0,
              w: 595.276,
              h: 7,
              color: "#1f9df2",
              margin: [0, 0, 0, 0], // [left, top, right, bottom]
            },
          ],
        },

        {
          text: "Payment Details",
          fontSize: 18,
          bold: true,
          alignment: "left",
          margin: [0, 20, 0, 10],
        },
        {
          text: `Date: ${data?.createdAt || ""}`,
          margin: [0, 0, 0, 25],
        },
        {
          text: `Billed To:`,
          margin: [0, 0, 0, 10],
          bold: true,
        },
        {
          text: `Name: ${data?.firstName || ""} ${data?.lastName || ""}`,
          margin: [0, 0, 0, 10],
        },
        {
          text: `Address: ${data?.address || ""}\n${
            data?.city ? `${data.city},` : ""
          } ${data?.country || ""}`,
          margin: [0, 0, 0, 10],
        },
        {
          text: `Email: ${data?.email || ""}`,
          margin: [0, 0, 0, 10],
        },
        {
          text: `Mobile: ${data?.mobile || ""}`,
          margin: [0, 0, 0, 10],
        },
        {
          text: "Order Details:",
          bold: true,
          margin: [0, 20, 0, 10],
        },
        {
          table: {
            widths: ["*", "auto"],
            body: [
              [
                {
                  text: "Order ID",
                  fillColor: "#f2f2f2",
                  margin: [5, 5, 5, 5],
                },
                {
                  text: data?.orderId || "",
                  fillColor: "#f2f2f2",
                  margin: [5, 5, 5, 5],
                },
              ],
              [
                { text: "Course", fillColor: "#f2f2f2", margin: [5, 5, 5, 5] },
                {
                  text: data?.course || "",
                  fillColor: "#f2f2f2",
                  margin: [5, 5, 5, 5],
                },
              ],
              [
                { text: "Amount", fillColor: "#f2f2f2", margin: [5, 5, 5, 5] },
                {
                  text: `LKR ${data?.amount ? data.amount.toFixed(2) : ""}`,
                  fillColor: "#f2f2f2",
                  margin: [5, 5, 5, 5],
                },
              ],
              [
                { text: "Remarks", fillColor: "#f2f2f2", margin: [5, 5, 5, 5] },
                {
                  text: data?.remarks || "",
                  fillColor: "#f2f2f2",
                  margin: [5, 5, 5, 5],
                },
              ],
            ],
            headerRows: 1, // first row header
            fillColor: "#f2f2f2",
            margin: [0, 5, 0, 15],
          },
          layout: {
            hLineWidth: (i: number, node: any) => {
              return 1;
            },
            vLineWidth: (i: number, node: any) => {
              return 1;
            },
            hLineColor: (i: number, node: any) => {
              return "#d9d9d9";
            },
            vLineColor: (i: number, node: any) => {
              return "#d9d9d9";
            },
          },
        },
        {
          text: "www.skillsurf.lk \n For all financial inquiries contact us at carelfinances@gmail.com",
          fontSize: 10,

          alignment: "center",
          margin: [0, 20, 0, 10],
        },
      ],
    };

    pdfMake
      .createPdf(documentDefinition)
      .download(`Skill Surf payment receipt #${data?.orderId || ""}`);
  };

  return (
    <div className="fade-in">
      <NavBar background="bg-background" />
      <section className="section-payment-details">
        <div>
          <div className="container  p-lg-5 p-3">
            <div className="d-flex align-items-center justify-content-between">
              <h2 className="text-secondary fs-3">Payment Details</h2>
            </div>

            <div className="row">
              <div className="col-lg-8 mt-3">
                <div className="payment-detail-info-card p-5 mt-3 h-100">
                  <div className="payment-data">
                    <h5>Order ID</h5>
                    <p>{state?.orderId || ""}</p>
                  </div>

                  <div className="payment-data">
                    <h5>Date & Time</h5>
                    <p>{state?.createdAt || ""}</p>
                  </div>

                  <div className="payment-data">
                    <h5>Course(s)</h5>
                    <div className="purchased-item p-3 my-3">
                      {state?.course || ""}
                    </div>
                  </div>

                  {state?.course === "Other" && (
                    <div className="payment-data">
                      <h5>Remarks</h5>
                      <p>{state?.remarks || ""}</p>
                    </div>
                  )}
                </div>
              </div>
              <div className="col-lg-4 mt-3">
                <div className="row">
                  <div className="col-md-12">
                    <div className="payment-detail-info-card p-5 mt-3 ">
                      <div className="payment-data">
                        <h5>Billed To</h5>
                        <p></p>
                      </div>

                      <div
                        className="d-flex align-items-center justify-content-start p-0 m-0"
                        style={{ height: "70px" }}
                      >
                        <img
                          src={profile_icon}
                          style={{ width: "70px" }}
                          alt="user profile"
                        />
                        <p className="fs-5 mx-3 mt-2">
                          {`${state?.firstName || ""} ${state?.lastName || ""}`}
                        </p>
                      </div>
                      <hr />
                      <div className="" style={{ color: "#8D8D8D" }}>
                        <p>{state?.email || ""}</p>
                        <p>{state?.mobile || ""}</p>
                        <p>{`${state?.address ? `${state.address},` : ``} ${
                          state?.city || ""
                        }`}</p>
                        <p>{state?.country || ""}</p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-12">
                    <div className="amount-card p-5 mt-3 h-100">
                      <div className="payment-data">
                        <h5 className="text-white">Total Amount</h5>
                      </div>

                      <h2 className="text-white">{`${
                        state?.amount
                          ? state.amount.toLocaleString("en-US", {
                              style: "currency",
                              currency: "LKR",
                            })
                          : ``
                      }`}</h2>
                      <hr style={{ borderBlockColor: "#fff" }} />

                      <>
                        <Button
                          className="text-white register-button px-3 py-3 w-100 "
                          style={{ fontWeight: 300 }}
                          onClick={() => {
                            generatePaymentReceiptPDF(state);
                          }}
                        >
                          Download Payment Receipt
                        </Button>

                        <div className="text-center pt-2">
                          <Link to="/">
                            <p className="text-white">Back to Home</p>
                          </Link>
                        </div>
                      </>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer isFinancialInquiries={true} />
    </div>
  );
};

export default PaymentDetails;
